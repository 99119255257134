import React from 'react';
import { navigate, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandFonts,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Link, Svg, Button } from './ui';
import { buildUrl } from '../utils';
import calendarIcon from '../images/calendar.inline.svg';

const StyledBlogCard = styled.article`
  cursor: pointer;

  ${({ featured }) => {
    if (!featured) {
      return css`
        text-align: center;
      `;
    }
  }}
`;

const StyledImageWrapper = styled.div`
  position: relative;
`;

const StyledImage = styled(GatsbyImage)`
  ${({ isSidebar }) => {
    if (isSidebar) {
      return css`
        ${maxBreakpointQuery.smedium`
          ${visuallyHidden()}
        `}

        ${minBreakpointQuery.smedium`
          margin-bottom: 20px;
        `}
      `;
    }
  }}
`;

const StyledHeading = styled.h3`
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.regular};
  color: ${standardColours.black};
  ${fontSize(20)};

  ${({ featured }) => {
    if (featured) {
      return css`
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        ${fontSize(20)};
        background-color: ${standardColours.transparentWhite(0.75)};
        padding: 25px;

        ${minBreakpointQuery.medium`
          padding: 25px 30px;
        `}

        ${minBreakpointQuery.mlarge`
          padding: 25px 40px;
          ${fontSize(25)};
        `}

        ${minBreakpointQuery.large`
          padding: 30px 50px;
          ${fontSize(30)};
        `}
      `;
    } else {
      return css`
        margin-top: ${({ isSidebar }) => (isSidebar ? '0' : '20')}px;

        ${minBreakpointQuery.mlarge`
          ${fontSize(25)};
        `}
      `;
    }
  }}
`;

const StyledIntro = styled.div`
  ${({ featured }) => {
    if (featured) {
      return css`
        margin: 15px 0;

        ${minBreakpointQuery.medium`
          margin: 20px 0;
        `}
      `;
    } else {
      return css`
        margin: 20px 0;
      `;
    }
  }}
`;

const StyledPublishedDate = styled.div`
  display: flex;
  align-items: center;

  ${({ featured }) => {
    if (featured) {
      return css`
        margin-bottom: 15px;

        ${minBreakpointQuery.medium`
          margin-bottom: 20px;
        `}
      `;
    } else {
      return css`
        margin-bottom: 20px;
        justify-content: center;
      `;
    }
  }}
`;

const StyledIcon = styled(Svg)`
  width: 10px;
  height: 10px;
  margin-right: 10px;

  ${minBreakpointQuery.medium`
    width: 16px;
    height: 16px;
  `}
`;

const StyledDate = styled.p`
  ${fontSize(12)};
  color: ${standardColours.black};
  line-height: 1.6;

  ${minBreakpointQuery.mlarge`
    ${fontSize(14)};
  `}
`;

const StyledIntroText = styled.p`
  ${fontSize(14)};
  line-height: 1.6;
`;

const BlogCard = ({
  heading,
  slug,
  image,
  introText,
  firstPublishedAt,
  featured,
  isSidebar,
  ...props
}) => {
  const urlPath = `blog/${slug}`;

  return (
    <StyledBlogCard
      onClick={() => navigate(buildUrl(urlPath))}
      featured={featured}
      {...props}
    >
      <StyledImageWrapper>
        <StyledImage
          image={image.gatsbyImageData}
          alt={image.alt}
          isSidebar={isSidebar}
        />
        <StyledHeading featured={featured} isSidebar={isSidebar}>
          <Link to={urlPath}>{heading}</Link>
        </StyledHeading>
      </StyledImageWrapper>
      <StyledIntro featured={featured}>
        <StyledPublishedDate featured={featured}>
          <StyledIcon image={calendarIcon} />
          <StyledDate>{firstPublishedAt}</StyledDate>
        </StyledPublishedDate>
        {!isSidebar && <StyledIntroText>{introText}</StyledIntroText>}
      </StyledIntro>
      <Button to={urlPath}>Read More</Button>
    </StyledBlogCard>
  );
};

export default BlogCard;

export const BlogCardFragment = graphql`
  fragment BlogCardFragment on DatoCmsBlog {
    title
    slug
    featuredImage {
      gatsbyImageData(width: 720, height: 385)
      alt
    }
    introText
    meta {
      firstPublishedAt(formatString: "DD MMM YYYY")
    }
  }
`;
