import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SubBanner from '../components/SubBanner';
import BlogListing from '../components/BlogListing';

const BlogCategoryTemplate = ({
  data: {
    datoCmsBlogCategory: { seoMetaTags, title, bannerImage },
    allDatoCmsBlogCategory: { categories },
    allDatoCmsBlog: { nodes },
  },
  location,
}) => (
  <Layout seo={seoMetaTags}>
    <main>
      <SubBanner heading={title} image={bannerImage} />
      {nodes.length > 0 && (
        <BlogListing
          items={nodes}
          categories={categories}
          currentUrl={location.pathname}
        />
      )}
    </main>
  </Layout>
);

export const BlogCategoryTemplateQuery = graphql`
  query BlogCategoryTemplateQuery($id: String!) {
    datoCmsBlogCategory(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        ...SubBannerImageFragment
      }
    }
    allDatoCmsBlogCategory {
      categories: nodes {
        title
        slug
      }
    }
    allDatoCmsBlog(
      sort: { order: DESC, fields: meta___firstPublishedAt }
      filter: { category: { id: { eq: $id } } }
    ) {
      nodes {
        ...BlogCardFragment
      }
    }
  }
`;

export default BlogCategoryTemplate;
