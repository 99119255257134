import React from 'react';
import styled, { css } from 'styled-components';
import {
  maxBreakpointQuery,
  minBreakpointQuery,
  sectionMargins,
  visuallyHidden,
} from '../styles';
import { Container, Heading, Link } from './ui';
import FilterLinks from './FilterLinks';
import BlogCard from './BlogCard';

const StyledBlogListing = styled.section`
  ${sectionMargins()}
`;

const StyledHeading = styled(Heading)`
  ${visuallyHidden}
`;

const StyledWrapper = styled.div`
  display: grid;

  ${({ featured }) => {
    if (featured) {
      return css`
        ${maxBreakpointQuery.smedium`
          row-gap: 25px;
        `}

        ${minBreakpointQuery.smedium`
          grid-template-columns: repeat(2, 1fr);
          column-gap: 20px;
        `}

        ${minBreakpointQuery.medium`
          column-gap: 30px;
        `}

        ${minBreakpointQuery.mlarge`
          column-gap: 40px;
        `}
      `;
    } else {
      return css`
        row-gap: 25px;

        ${minBreakpointQuery.small`
          grid-template-columns: repeat(2, 1fr);
          column-gap: 20px;
        `}

        ${minBreakpointQuery.smedium`
          row-gap: 35px;
        `}

        ${minBreakpointQuery.medium`
          grid-template-columns: repeat(3, 1fr);
          row-gap: 50px;
        `}

        ${minBreakpointQuery.mlarge`
          row-gap: 70px;
        `}

        ${minBreakpointQuery.large`
          row-gap: 90px;
        `}

        ${minBreakpointQuery.xlarge`
          row-gap: 110px;
        `}
      `;
    }
  }}
`;

const BlogListing = ({ items, categories, currentUrl, featured }) => (
  <StyledBlogListing>
    {!featured && (
      <FilterLinks text={'Filter by category:'}>
        {currentUrl && currentUrl.split('/')[1] && (
          <li>
            <Link to={`/blog/`}>All</Link>
          </li>
        )}
        {categories.map(({ title, slug }) => (
          <li>
            <Link
              to={`/blog/${slug}/` === currentUrl ? `` : `/blog/${slug}/`}
              className={`/blog/${slug}/` === currentUrl && 'active'}
            >
              {title}
            </Link>
          </li>
        ))}
      </FilterLinks>
    )}
    <Container>
      <StyledWrapper featured={featured}>
        <StyledHeading>
          {featured ? 'Featured Blog Posts' : 'Latest Blog Posts'}
        </StyledHeading>
        {items.map(
          (
            {
              title,
              slug,
              featuredImage,
              introText,
              meta: { firstPublishedAt },
            },
            id
          ) => (
            <BlogCard
              key={id}
              heading={title}
              slug={slug}
              image={featuredImage}
              introText={introText}
              firstPublishedAt={firstPublishedAt}
              featured={featured}
            />
          )
        )}
      </StyledWrapper>
    </Container>
  </StyledBlogListing>
);

export default BlogListing;
