import React from 'react';
import styled from 'styled-components';
import {
  brandColours,
  fontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  standardTransition,
} from '../styles';
import { Container } from './ui';

const StyledFilterLinks = styled.nav`
  ${sectionMargins()}
  background-color: ${standardColours.lightGrey};
  padding: 25px;
  ${fontSize(14)};

  ${minBreakpointQuery.small`
    ${fontSize(16)};
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(18)};
    padding: 40px;
  `}
`;

const StyledWrapper = styled.div`
  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
  `}
`;

const StyledText = styled.p`
  ${minBreakpointQuery.medium`
    flex-shrink: 1;
  `}
`;

const StyledItems = styled.ul`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 18px;

  ${minBreakpointQuery.medium`
    column-gap: 20px;
  `}

  ${maxBreakpointQuery.medium`
    margin-top: 25px;
  `}

  a {
    background-color: ${standardColours.white};
    padding: 12px 15px;
    transition: ${standardTransition('background-color')};

    ${minBreakpointQuery.medium`
      padding: 15px 20px;
    `}

    ${minBreakpointQuery.mlarge`
      padding: 15px 25px;
    `}

    &:hover {
      background-color: ${brandColours.primary};
    }

    &.active {
      background-color: ${brandColours.primary};
    }
  }
`;

const FilterLinks = ({ text, children, ...props }) => (
  <StyledFilterLinks {...props}>
    <Container>
      <StyledWrapper>
        <StyledText>{text}</StyledText>
        <StyledItems>{children}</StyledItems>
      </StyledWrapper>
    </Container>
  </StyledFilterLinks>
);

export default FilterLinks;
